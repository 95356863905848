import React from "react"
import axios from "axios"
import { Button } from "react-bootstrap"
import CareerHeader from "../components/header/careerHeader"
import ContactBtn from "../components/ContactBtn/contactbtn"
import firebase from "firebase/app"
import Footer from "../components/footer/footer"
import { Form } from "react-bootstrap"
import { Input } from "../components/Input/Input"
import { Link } from "gatsby"
import SEO from "../components/seo"
import * as styles from "../styles/pages/career.module.css"
import { Typeahead } from "react-bootstrap-typeahead"
import { useState } from "react"
import "react-bootstrap-typeahead/css/Typeahead.css"

const GetQuote = () => {
  const config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_DB_URL,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
  }

  const [step, setStep] = useState(1)
  const [quoteVal, setQuoteVal] = useState({
    roles: [],
    specifications: "",
    projectType: "",
    startDate: "",
    duration: "",
    stepErr: " ",
    contact: {
      companyName: "",
      companyNameErr: "",
      name: "",
      nameErr: "",
      email: "",
      emailErr: "",
      phone: "",
      phoneErr: "",
      website: "",
      websiteErr: "",
    },
  })
  const [technologies, setTechnologies] = useState([])

  const options = [
    "React",
    "Angular",
    "Node.JS",
    "HTML/CSS",
    "Selenium",
    "django",
    "Vue",
    "Python",
    "Docker",
    "Kubernetes",
    "Devops",
    "Cypress",
    "Javascript",
    "Typescript",
    "Manual testing",
    "JQuery",
    "Shell scripting",
  ]

  const HandleBackStep = () => {
    let temp = step - 1
    setStep(temp)
  }

  const HandleNextStep = ele => {
    if (ele !== "") {
      let temp = step + 1
      setQuoteVal({ ...quoteVal, stepErr: "" })
      setStep(temp)
    } else {
      setQuoteVal({ ...quoteVal, stepErr: "This field is required" })
      return null
    }
  }

  const HandleNextStepArray = ele => {
    if (ele.length !== 0) {
      let temp = step + 1
      setQuoteVal({ ...quoteVal, stepErr: "" })
      setStep(temp)
    } else {
      setQuoteVal({ ...quoteVal, stepErr: "Please choose atleast one option" })
      return null
    }
  }

  const HandleSubmit = () => {
    if (!firebase) {
      firebase.initializeApp(config)
    }

    let quoteData = {
      email: quoteVal.contact.email,
      name: quoteVal.contact.name,
      companyName: quoteVal.contact.companyName,
      type: quoteVal.projectType,
      specs: quoteVal.specifications,
      duration: quoteVal.duration,
      start: quoteVal.startDate,
      number: quoteVal.contact.phone,
      website: quoteVal.contact.website,
      roles: quoteVal.roles.join(),
      languages: technologies.join(),
    }

    if (validateContactForm()) {
      axios
        .post(
          "https://us-central1-yogi-r-website-forms.cloudfunctions.net/getQuote",
          quoteData
        )
        .then(res => {
          setStep(step + 1)
        })
    }
  }

  const handleContactFormChange = e => {
    setQuoteVal({
      ...quoteVal,
      contact: {
        ...quoteVal.contact,
        [e.target.name]: e.target.value,
        [e.target.name + "Err"]: "",
      },
    })
  }

  const validateContactForm = () => {
    const emailRegex = /^[a-z][a-z0-9+-_.]{1,}@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/
    const phoneRegex = /^[0-9]*$/
    let temp = quoteVal

    let valid = true

    if (quoteVal.contact.email === "") {
      temp.contact.emailErr = "This field is required."
      valid = false
    } else if (!emailRegex.test(quoteVal.contact.email)) {
      temp.contact.emailErr = "Please enter a correct email ID"
      valid = false
    }
    if (quoteVal.contact.name === "") {
      temp.contact.nameErr = "This field is required."
      valid = false
    }
    if (quoteVal.contact.phone === "") {
      temp.contact.phoneErr = "This field is required."
      valid = false
    } else if (!phoneRegex.test(quoteVal.contact.phone)) {
      temp.contact.phoneErr = "Please enter a valid mobile number"
      valid = false
    }
    if (quoteVal.contact.website === "") {
      temp.contact.websiteErr = "This field is required."
      valid = false
    }
    if (quoteVal.contact.companyName === "") {
      temp.contact.companyNameErr = "This field is required."
      valid = false
    }

    setQuoteVal({ ...temp })
    return valid
  }

  const getQuoteStep = () => {
    switch (step) {
      case 1:
        return (
          <div className={styles.stepContainer}>
            <h3>What type of project are you hiring for?</h3>
            <Form className={styles.careerForm}>
              {[
                "New idea or project",
                "Existing project that needs more resources",
                "Ongoing assistance or consultation",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    name="type"
                    checked={quoteVal.projectType === label ? true : false}
                    onChange={() => {
                      setQuoteVal({ ...quoteVal, projectType: label })
                    }}
                    className={styles.checkboxLabel}
                    type={"radio"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{quoteVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Link to="/work-with-us">
                  <Button className={styles.backBtn}>Exit</Button>
                </Link>
                <Button
                  onClick={() => {
                    HandleNextStep(quoteVal.projectType)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 2:
        return (
          <div className={styles.stepContainer}>
            <h3>In which area do you want us to work for you ?</h3>
            <Form className={styles.careerForm}>
              {[
                "Front-end developer",
                "Back-end developer",
                "Full-stack developer",
                "Automation Engineer",
                "Data scientist",
                "DevOps",
                "Others",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    checked={
                      quoteVal.roles.indexOf(label) !== -1 ? true : false
                    }
                    onChange={() => {
                      let temp = quoteVal.roles
                      let index = temp.indexOf(label)
                      if (index !== -1) {
                        temp.splice(index, 1)
                        setQuoteVal({ ...quoteVal, roles: [...temp] })
                      } else {
                        temp.push(label)
                        setQuoteVal({
                          ...quoteVal,
                          stepErr: "",
                          roles: [...temp],
                        })
                      }
                    }}
                    name="role"
                    className={styles.checkboxLabel}
                    type={"checkbox"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{quoteVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    HandleNextStepArray(quoteVal.roles)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 3:
        return (
          <div className={styles.stepContainer}>
            <h3>Do you have product specifications ready ?</h3>
            <Form className={styles.careerForm}>
              {[
                "I have a rough idea of what I want to build",
                "I have a clear idea of what I want to build, but I do not have written specifications and/or wireframes",
                "I have clear written specifications and/or wireframes",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    name="specifications"
                    checked={quoteVal.specifications === label ? true : false}
                    onChange={() => {
                      setQuoteVal({ ...quoteVal, specifications: label })
                    }}
                    className={styles.checkboxLabel}
                    type={"radio"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{quoteVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    HandleNextStep(quoteVal.specifications)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 4:
        return (
          <div className={styles.stepContainer}>
            <h3>
              List the technologies that the developer must be familiar in.
            </h3>
            <Form className={styles.careerForm}>
              <Typeahead
                id="career-typeahead"
                allowNew
                multiple
                options={options}
                selected={technologies}
                onChange={setTechnologies}
                placeholder="Choose skill..."
              />
            </Form>
            <div className={styles.errContainer}>
              <p>{quoteVal.stepErr}</p>
            </div>
            <div className={styles.controlsContainer}>
              <Button onClick={HandleBackStep} className={styles.backBtn}>
                Back
              </Button>
              <Button
                onClick={() => {
                  HandleNextStepArray(technologies)
                }}
                className={styles.nextBtn}
              >
                Next
              </Button>
            </div>
          </div>
        )
      case 5:
        return (
          <div className={styles.stepContainer}>
            <h3>How long do you need the developer ?</h3>
            <Form className={styles.careerForm}>
              {[
                " < 3 month",
                "3 - 6 month",
                "6 - 12 month",
                "1+ year",
                "I'am not sure",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    name="duration"
                    checked={quoteVal.duration === label ? true : false}
                    onChange={() => {
                      setQuoteVal({ ...quoteVal, duration: label })
                    }}
                    className={styles.checkboxLabel}
                    type={"radio"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{quoteVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    HandleNextStep(quoteVal.duration)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 6:
        return (
          <div className={styles.stepContainer}>
            <h3>When do you need the developer to start?</h3>
            <Form className={styles.careerForm}>
              {[
                "Immediately",
                "1 - 2 weeks",
                "Next month",
                "In 2 - 3 months",
              ].map(label => (
                <div key={label}>
                  <Form.Check
                    name="startDate"
                    checked={quoteVal.startDate === label ? true : false}
                    onChange={() => {
                      setQuoteVal({ ...quoteVal, startDate: label })
                    }}
                    className={styles.checkboxLabel}
                    type={"radio"}
                    id={"title-" + label}
                    label={label}
                  />
                </div>
              ))}
              <div className={styles.errContainer}>
                <p>{quoteVal.stepErr}</p>
              </div>
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button
                  onClick={() => {
                    HandleNextStep(quoteVal.startDate)
                  }}
                  className={styles.nextBtn}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        )
      case 7:
        return (
          <div className={styles.stepContainer}>
            <h3>
              Great, Please give your contact details. Our representatives will
              contact you as soon as possible to discuss further.
            </h3>
            <Form className={styles.contactForm}>
              <Input
                inpClass={styles.contactInp}
                errText={quoteVal.contact.nameErr}
                value={quoteVal.contact.name}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                placeholder="Full Name"
                name="name"
              />
              <Input
                inpClass={styles.contactInp}
                errText={quoteVal.contact.companyNameErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={quoteVal.contact.companyName}
                placeholder="Company Name"
                name="companyName"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Input
                    inpClass={styles.contactHalfInp}
                    errText={quoteVal.contact.emailErr}
                    onChangeCallBack={e => {
                      handleContactFormChange(e)
                    }}
                    value={quoteVal.contact.email}
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div>
                  <Input
                    inpClass={styles.contactHalfInp}
                    errText={quoteVal.contact.phoneErr}
                    onChangeCallBack={e => {
                      handleContactFormChange(e)
                    }}
                    value={quoteVal.contact.phone}
                    type="tel"
                    placeholder="Phone number"
                    name="phone"
                  />
                </div>
              </div>
              <Input
                inpClass={styles.contactInp}
                errText={quoteVal.contact.websiteErr}
                onChangeCallBack={e => {
                  handleContactFormChange(e)
                }}
                value={quoteVal.contact.website}
                type="text"
                placeholder="Website"
                name="website"
              />
              <div className={styles.controlsContainer}>
                <Button onClick={HandleBackStep} className={styles.backBtn}>
                  Back
                </Button>
                <Button onClick={HandleSubmit} className={styles.nextBtn}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )
      case 8:
        return (
          <div
            className={styles.stepContainer + " " + styles.thankyouContainer}
          >
            <div>
              <h3>
                Thank you,our team will get back to you in 3-4 business days.
              </h3>
            </div>
            <div>
              <Link to="/">
                <Button
                  className={styles.nextBtn}
                  style={{ marginTop: "20px" }}
                >
                  Go home
                </Button>
              </Link>
            </div>
          </div>
        )
      default:
        return (
          <div className={styles.stepContainer}>
            <h3>Unkown error.</h3>
            <Link className={styles.nextBtn} to="/">
              Go home
            </Link>
          </div>
        )
    }
  }

  return (
    <body className={styles.body}>
      <CareerHeader progress={step * 12.5} />
      <SEO title="Hire developers from YRT" />
      <div className={styles.careerContainer}>
        <div className={styles.formContainer}>{getQuoteStep()}</div>
        <div className={styles.infoContainer}>
          <div class={styles.scheduleMeet}>
            <h6>Want to speak with us first ?</h6>
            <p>Schedule a call and our team will get back to you ASAP</p>
            <Button href="https://calendly.com/yogirtech/client-meeting">
              Schedule a call
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.mobileMeet}>
        <ContactBtn />
      </div>
      <Footer />
    </body>
  )
}

export default GetQuote
